import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'primereact/resources/themes/bootstrap4-dark-blue/theme.css';
import './DatasetSelector.css'; 

const StationsTable: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className="card">
            <div>
                <label style={{ marginRight: "8px", padding: 0, fontSize: "3em", fontWeight: "bold" }}>
                    Select a dataset 
                </label>
            </div>
            <div className="button-container"> {/* Wrap buttons in this container */}
                <button onClick={() => { 
                    navigate('/sector-selection'); 
                }}>
                    Industrial Wastewater By Facility
                </button>
                <button onClick={() => {
                    navigate('/inland-lake-drinking-water-quality'); 
                }}>
                    Inland Lake Drinking Water Quality
                </button>
                <button onClick={() => {
                    navigate('/stream-water-quality'); 
                }}>
                    Stream Water Quality
                </button>
            </div>
        </div>
    );
}

export default StationsTable;
