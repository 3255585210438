import { Reading } from "../types/Reading";

const BASE_API_URL = "/api";
console.log("API_URL: ", BASE_API_URL);

export const getWastewaterReadings = async (companyCode: string | null | undefined): Promise<Reading[]> => {
    const getReadingsURL = `${BASE_API_URL}/industrial-wastewater/v1/stations/${companyCode}/readings`;
    console.log(`Fetching stations from industrial wastewater at ${getReadingsURL}`);
    try {
        const response = await fetch(getReadingsURL);
        console.log(response);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const readings = await response.json();
        console.log("API response: ", readings);
        return readings;
    } catch (error) {
        console.error("Error fetching stations: ", error);
        return [];
    }
};

export const getLakeWaterReadings = async (stationCode: string | null | undefined): Promise<Reading[]> => {
    const getReadingsURL = `${BASE_API_URL}/inland-lake/v1/stations/${stationCode}/readings`;
    console.log(`Fetching stations from inland lake at ${getReadingsURL}`);
    try {
        const response = await fetch(getReadingsURL);
        console.log(response);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const readings = await response.json();
        console.log("API response: ", readings);
        return readings;
    } catch (error) {
        console.error("Error fetching stations: ", error);
        return [];
    }
};

export const getStreamWaterReadings = async (stationCode: string | null | undefined): Promise<Reading[]> => {
    const getReadingsURL = `${BASE_API_URL}/stream/v1/stations/${stationCode}/readings`;
    console.log(`Fetching stations from inland lake at ${getReadingsURL}`);
    try {
        const response = await fetch(getReadingsURL);
        console.log(response);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const readings = await response.json();
        console.log("API response: ", readings);
        return readings;
    } catch (error) {
        console.error("Error fetching stations: ", error);
        return [];
    }
};

    // New function for fetching lake quality data by lake name
    export const getLakeQualityData = async (lakeName: string): Promise<Reading[]> => {
    const getReadingsURL = `${BASE_API_URL}/inland-lake/v1/lake/${lakeName}/quality`;
    console.log(`Fetching water quality data for lake ${lakeName} at ${getReadingsURL}`);
    try {
        const response = await fetch(getReadingsURL);
        console.log(response);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const readings = await response.json();
        console.log("API response: ", readings);
        return readings;
    } catch (error) {
        console.error("Error fetching lake quality data: ", error);
        return [];
    }
};

    export const getMonthlyAveragesForStation = async (stationCode: string | null | undefined): Promise<Reading[]> => {
    const getReadingsURL = `${BASE_API_URL}/stream/v1/stations/${stationCode}/monthly-averages`;
    console.log(`Fetching monthly averages for station ${stationCode} at ${getReadingsURL}`);
    try {
        const response = await fetch(getReadingsURL);
        console.log(response);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const readings = await response.json();
        console.log("API response: ", readings);
        return readings;
    } catch (error) {
        console.error("Error fetching monthly averages: ", error);
        return [];
    }
};


