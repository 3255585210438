import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Station } from '../types/Station';
import { Reading } from '../types/Reading';
import { getStations, getTop5LeadStreams } from '../services/stationsService';
import { getStreamWaterReadings, getMonthlyAveragesForStation } from '../services/readingsService';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

type DataEntry = {
    date: string;
    result: number;
};

type LeadStreamData = {
    waterBodyName: string | null;
    result: number | null;
};

type MonthlyAverage = {
    parameterCode: string;
    data: DataEntry[];
};

type ChartData = {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        fill: boolean;
        borderColor: string;
        tension: number;
    }[];
} | null;

type BarChartData = {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        backgroundColor: string;
    }[];
} | null;

const StreamWaterQualityTable: React.FC = () => {
    const navigate = useNavigate();
    const [selectedStation, setSelectedStation] = useState<Station | null>(null);
    const [stations, setStations] = useState<Station[]>([]);
    const [readings, setReadings] = useState<Reading[]>([]);
    const [chartData, setChartData] = useState<ChartData | null>(null);
    const [barChartData, setBarChartData] = useState<BarChartData | null>(null);
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [stationCode, setStationCode] = useState<string | null>(null);
    const [displayDialog, setDisplayDialog] = useState(false);

    const onRowSelect = (event: { data: Station }) => {
        setSelectedStation(event.data);
        setDisplayDialog(true);
        loadChartData(event.data.stationDetails ?? '');
    };

    const closeDialog = () => {
        setDisplayDialog(false);
        setSelectedStation(null);
        setReadings([]);
    };

    useEffect(() => {
        getStations("stream-water-quality").then((data) => {
            setStations(data);
        });
    }, []);

    useEffect(() => {
        getStreamWaterReadings(selectedStation?.stationDetails)
            .then((data) => setReadings(data));
    }, [selectedStation]);

    useEffect(() => {
        if (stationCode) {
            loadChartData(stationCode);
        }
    }, [stationCode]);

    const loadChartData = async (stationCode: string) => {
        const data = await getMonthlyAveragesForStation(stationCode) as unknown as MonthlyAverage[];
        if (data && data.length > 0) {
            const labels = data[0].data.map((entry: DataEntry) => entry.date);
    
            const datasets = data.map((parameter) => ({
                label: getParameterLabel(parameter.parameterCode), // Use a function to get readable labels
                data: parameter.data.map((entry: DataEntry) => entry.result),
                fill: false,
                borderColor: getColor(parameter.parameterCode),
                tension: 0.1,
            }));
    
            setChartData({ labels, datasets });
        }
    };

    const loadBarChartData = async () => {
        const data: LeadStreamData[] = await getTop5LeadStreams(); // Explicitly type data
        
        if (data && data.length > 0) {
            const labels = data.map((entry) => entry.waterBodyName || 'Unknown Stream'); // Default to 'Unknown Stream' if null
            const results = data.map((entry) => entry.result ?? 0); // Default to 0 if result is null
    
            setBarChartData({
                labels,
                datasets: [
                    {
                        label: 'Lead Content (PBUT)',
                        data: results,
                        backgroundColor: 'rgba(255, 99, 132, 0.6)',
                    },
                ],
            });
        }
    };

    useEffect(() => {
        loadBarChartData();
    }, []);

    const getParameterLabel = (parameterCode: string) => {
        switch (parameterCode) {
            case 'CLIDUR':
                return 'Chlorine';
            case 'PH':
                return 'pH';
            case 'PPUT':
                return 'Phosphorus';
            default:
                return parameterCode;
        }
    };

    const getColor = (parameterCode: string) => {
        switch (parameterCode) {
            case 'CLIDUR': return 'rgba(75, 192, 192, 1)';
            case 'PH': return 'rgba(255, 99, 132, 1)';
            case 'PPUT': return 'rgba(54, 162, 235, 1)';
            default: return 'rgba(153, 102, 255, 1)';
        }
    };

    return (
        <div className="card" style={{ display: 'flex', flexDirection: 'row' }}>
            {/* Left section for chart and station selection */}
            <div style={{ width: '50%', marginRight: '20px' , position: 'sticky', bottom: '350px'}}>
                <label>Select Station Code:</label>
                <select onChange={(e) => setStationCode(e.target.value || null)} style={{ marginBottom: '20px', width: '100%' }}>
                    <option value="">Select a station</option>
                    {stations.map((station) => (
                        <option key={station.stationDetails ?? ''} value={station.stationDetails ?? ''}>
                            {station.stationDetails}
                        </option>
                    ))}
                </select>

                {chartData && (
                    <Line
                        data={chartData}
                        data-testid="line-chart"
                        options={{
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: 'top',
                                },
                                title: {
                                    display: true,
                                    text: 'Monthly Water Quality Trends',
                                },
                            },
                        }}
                    />
                )}

                {barChartData && (
                    <Bar
                        data={barChartData}
                        data-testid="bar-chart"
                        options={{
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: 'top',
                                },
                                title: {
                                    display: true,
                                    text: 'Top 5 Streams with Highest Lead Content',
                                },
                            },
                        }}
                    />
                )}
            </div>

            {/* Main DataTable on the right */}
            <div style={{ flex: 1 }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: "10px",
                        paddingRight: "8%",
                    }}
                >
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            value={globalFilter}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setGlobalFilter(e.target.value)
                            }
                            placeholder="Search..."
                            style={{ width: "150%", maxWidth: "400px", marginRight: "10px" }}
                        />
                    </span>
                </div>
                <DataTable
                    value={stations}
                    selectionMode="single"
                    selection={selectedStation!} onSelectionChange={(e) => setSelectedStation(e.value)}
                    onRowSelect={onRowSelect}
                    dataKey="stationDetails"
                    paginator
                    rows={20}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                    metaKeySelection={false}
                    tableStyle={{ minWidth: '50rem', maxWidth: '95vw', margin: '2.5vw' }}
                    globalFilter={globalFilter}
                    filterDisplay="menu"
                >
                    <Column field="lastUpdated" header="Last Updated" sortable></Column>
                    <Column field="stationDetails" header="Station Details" sortable></Column>
                    <Column field="waterBodyName" header="Stream Name" sortable></Column>
                </DataTable>
            </div>

            <Dialog
                header="Station Readings"
                visible={displayDialog}
                style={{ width: '80vw', borderRadius: '8px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', overflowX: 'scroll' }}
                onHide={closeDialog}
            >
                {selectedStation && (
                    <DataTable value={readings} dataKey="date">
                        <Column field="parameterCode" header="Parameter Code" sortable></Column>
                        <Column field="parameterDescription" header="Parameter Description" sortable></Column>
                        <Column field="year" header="Year" sortable></Column>
                        <Column field="date" header="Date" sortable></Column>
                        <Column field="time" header="Time" sortable></Column>
                        <Column field="fieldNumber" header="Field Number" sortable></Column>
                        <Column field="remarkCode" header="Remark Code" sortable></Column>
                        <Column field="result" header="Measured Result" sortable></Column>
                        <Column field="valueQualifier" header="Value Qualifier" sortable></Column>
                        <Column field="units" header="Units" sortable></Column>
                        <Column field="analysisMethod" header="Analysis Method" sortable></Column>
                    </DataTable>
                )}
            </Dialog>

            {/* Back button */}
            <button className="button back-button" onClick={() => navigate(-1)}>
                Back
            </button>
        </div>
    );
};

export default StreamWaterQualityTable;
