import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Station } from '../types/Station';
import { Reading } from '../types/Reading';
import { getStations } from '../services/stationsService';
import { getLakeWaterReadings, getLakeQualityData } from '../services/readingsService';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const LakeWaterQualityTable: React.FC = () => {
    const navigate = useNavigate();
    const [stations, setStations] = useState<Station[]>([]);
    const [selectedStation, setSelectedStation] = useState<Station | null>(null);
    const [readings, setReadings] = useState<Reading[]>([]);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [toxicityData, setToxicityData] = useState<{ waterBodyName: string; toxicityScore: number }[]>([]);

    const onRowSelect = (event: { data: Station }) => {
        setSelectedStation(event.data);
        setDisplayDialog(true);
    };

    const closeDialog = () => {
        setDisplayDialog(false);
        setSelectedStation(null);
        setReadings([]);
    };

    useEffect(() => {
        getStations("inland-lake-drinking-water-quality").then(setStations);
    }, []);

    useEffect(() => {
        if (selectedStation) {
            setReadings([]);
            getLakeWaterReadings(selectedStation.stationDetails).then(setReadings);
        }
    }, [selectedStation]);

    useEffect(() => {
        async function fetchToxicityData() {
            const lakes = await getStations("inland-lake-drinking-water-quality");
            const toxicityResults = await Promise.all(
                lakes.map(async (lake: Station) => {
                    if (!lake.waterBodyName) return { waterBodyName: "Unknown", toxicityScore: 0 };
    
                    const lakeQualityData = await getLakeQualityData(lake.waterBodyName);
    
                    // Get the latest reading by accessing the first element
                    const latestReading = lakeQualityData[0];
    
                    if (!latestReading) return { waterBodyName: lake.waterBodyName, toxicityScore: 0 };
    
                    return {
                        waterBodyName: lake.waterBodyName,
                        toxicityScore: latestReading.toxicityScore,
                        ph: latestReading.ph,
                        chloride: latestReading.chloride,
                        totalPhosphorus: latestReading.totalPhosphorus,
                        sulfate: latestReading.sulfate,
                        sampleDate: latestReading.sampleDate,
                    };
                })
            );
            // Sort toxicity data from greatest to least
            toxicityResults.sort((a, b) => b.toxicityScore - a.toxicityScore);
            setToxicityData(toxicityResults);
        }
        fetchToxicityData();
    }, []);

    const dialogStyle = {
        width: '80vw',
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        overflowX: 'auto' as const,
    };

    const chartData = {
        labels: toxicityData.map(item => item.waterBodyName),
        datasets: [
            {
                label: 'Toxicity Score (ph + Cl + P + SO4)',
                data: toxicityData.map(item => item.toxicityScore),
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        indexAxis: 'y' as const, // Horizontal bar chart
        scales: {
            x: { beginAtZero: true },
        },
    };

    return (
        <div className="card">
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px", paddingRight: "8%" }}>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        value={globalFilter}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGlobalFilter(e.target.value)}
                        placeholder="Search..."
                        style={{ width: "150%", maxWidth: "400px", marginRight: "10px" }}
                    />
                </span>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <div style={{ width: '55%' }}>
                    <h3>Most Toxic Lakes</h3>
                    <Bar data={chartData} options={chartOptions} />
                </div>

                <DataTable
                    value={stations}
                    selectionMode="single"
                    selection={selectedStation!}
                    onSelectionChange={(e) => setSelectedStation(e.value)}
                    onRowSelect={onRowSelect}
                    dataKey="stationDetails"
                    paginator
                    rows={20}
                    totalRecords={stations.length}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                    metaKeySelection={false}
                    tableStyle={{ minWidth: "30rem", maxWidth: "95vw", margin: "0.5vw" }}
                    globalFilter={globalFilter}
                    filterDisplay="menu"
                    sortField="waterBodyName"
                    sortOrder={1}
                    style={{ width: '45%' }}
                >
                    <Column field="lastUpdated" header="Last Updated" sortable></Column>
                    <Column field="waterBodyName" header="Lake Name" sortable></Column>
                    <Column field="stationCode" header="Station Code" sortable></Column>
                    <Column field="stationDetails" header="Station Details" sortable></Column>
                </DataTable>
            </div>

            <Dialog header="Station Readings" visible={displayDialog} style={dialogStyle} onHide={closeDialog} className="modal">
                {selectedStation && (
                    <DataTable key={selectedStation.stationDetails} value={readings} dataKey="sampleDate">
                        <Column field="sampleDate" header="Sample Date" sortable></Column>
                        <Column field="granAlkalinity" header="Gran Alkalinity (mg/L)" sortable></Column>
                        <Column field="calcium" header="Calcium (mg/L)" sortable></Column>
                        <Column field="chloride" header="Chloride (mg/L)" sortable></Column>
                        <Column field="specificConductance" header="Specific Conductance (mcS/cm)" sortable></Column>
                        <Column field="dic" header="DIC (mg/L)" sortable></Column>
                        <Column field="doc" header="DOC (mg/L)" sortable></Column>
                        <Column field="iron" header="Iron (mcg/L)" sortable></Column>
                        <Column field="potassium" header="Potassium (mg/L)" sortable></Column>
                        <Column field="magnesium" header="Magnesium (mg/L)" sortable></Column>
                        <Column field="sodium" header="Sodium (mg/L)" sortable></Column>
                        <Column field="ammoniumAmmonia" header="Ammonium/NH3 (mcg/L)" sortable></Column>
                        <Column field="nitrateNitrite" header="Nitrate/Nitrite (mcg/L)" sortable></Column>
                        <Column field="tkn" header="Total Kjeldahl Nitrogen (mcg/L)" sortable></Column>
                        <Column field="tn" header="Total Nitrogen (mcg/L)" sortable></Column>
                        <Column field="ph" header="pH" sortable></Column>
                        <Column field="totalPhosphorus" header="Total Phosphorus (mcg/L)" sortable></Column>
                        <Column field="silicate" header="Silicate (mg/L)" sortable></Column>
                        <Column field="sulfate" header="Sulfate (mg/L)" sortable></Column>
                        <Column field="lastUpdated" header="Last Updated" sortable></Column>
                    </DataTable>
                )}
            </Dialog>


            <button className="button back-button" onClick={() => navigate(-1)}>
                Back
            </button>
        </div>
    );
};

export default LakeWaterQualityTable;
