// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

h2 {
    margin-bottom: 20px;
}

.button-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 100%;
}

.button {
    padding: 15px 20px;
    font-size: 1.2em;
    border: none;
    border-radius: 5px;
    background-color: #1a3779;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #0056b3;
}

.back-button {
    margin-top: 20px;
    background-color: #6c757d;
}

.back-button:hover {
    background-color: #5a6268;
}
`, "",{"version":3,"sources":["webpack://./src/components/SectorSelection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,yCAAyC;AAC7C;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".card {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n    margin: 20px;\n    border-radius: 8px;\n    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n}\n\nh2 {\n    margin-bottom: 20px;\n}\n\n.button-container {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: 20px;\n    width: 100%;\n}\n\n.button {\n    padding: 15px 20px;\n    font-size: 1.2em;\n    border: none;\n    border-radius: 5px;\n    background-color: #1a3779;\n    color: white;\n    cursor: pointer;\n    transition: background-color 0.3s;\n}\n\n.button:hover {\n    background-color: #0056b3;\n}\n\n.back-button {\n    margin-top: 20px;\n    background-color: #6c757d;\n}\n\n.back-button:hover {\n    background-color: #5a6268;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
