import React from "react";
import { ChemicalChartData } from "../types/ChemicalChartData";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions
} from "chart.js";
import "./ChemicalChart.css";

const ChemicalChart: React.FC<ChemicalChartData> = ({
  labels,
  values,
  unitOfMeasure,
  chemicalName
}) => {
  // Register chart components to use with Chart.js
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const data: ChartData<"line"> = {
    labels: labels,
    datasets: [
      {
        label: `${chemicalName} Levels over Time for Workstation`,
        data: values,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  // Define chart options with ChartOptions type
  const options: ChartOptions<"line"> = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Reading Date",
        },
      },
      y: {
        title: {
          display: true,
          text: unitOfMeasure,
        },
      },
    },
  };

  return values?.length > 0 ? (
    <div className="chemical-chart-wrapper">
      <Line role="chart" data={data} options={options}/>
    </div>
  ) : (
    <div>
      <em role="chart-alternative">No information available for the specified workstation chemical readings.</em>
    </div>
  );
};

export default ChemicalChart;
