import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SectorSelection.css';

const SectorSelection: React.FC = () => {
    const navigate = useNavigate();

    const handleSectorSelection = (sector: string) => {
        navigate(`/industrial-wastewater/${sector}`);
    };

    const handleBack = () => {
        navigate(-1); // navigate back to the previous page
    };

    return (
        <div className="card">
            <label style={{ marginRight: "8px", padding: 0, fontSize: "3em", fontWeight: "bold" }}>Select a sector </label>
            <div className="button-container"> 
                <button className="button" onClick={() => handleSectorSelection('organic-chemical-manufacturing')}>
                    Organic Chemical Manufacturing
                </button>
                <button className="button" onClick={() => handleSectorSelection('iron-and-steel')}>
                    Iron and Steel
                </button>
                <button className="button" onClick={() => handleSectorSelection('petroleum-refineries')}>
                    Petroleum Refineries
                </button>
                <button className="button" onClick={() => handleSectorSelection('pulp-and-paper')}>
                    Pulp and Paper
                </button>
                <button className="button" onClick={() => handleSectorSelection('metal-mining-and-refining')}>
                    Metal Mining and Refining
                </button>
                <button className="button" onClick={() => handleSectorSelection('industrial-minerals')}>
                    Industrial Minerals
                </button>
                <button className="button" onClick={() => handleSectorSelection('inorganic-chemicals')}>
                    Inorganic Chemicals
                </button>
                <button className="button" onClick={() => handleSectorSelection('electric-power-generation')}>
                    Electric Power Generation
                </button>
                <button className="button" onClick={() => handleSectorSelection('metal-casting')}>
                    Metal Casting
                </button>
            </div>
            <button className="button back-button" onClick={handleBack}>
                Back
            </button>
        </div>
    );
};

export default SectorSelection;
