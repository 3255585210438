import { Chemical } from "../types/Chemical";

const BASE_API_URL = "/api";

export const getChemicalGraphData = async (stationId: string | null | undefined, chemicalName: string | null | undefined): Promise<Chemical[]> => {
    let getChemicalsURL = "";

    console.log(chemicalName)

    const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json' // Set content type to JSON
        },
        body: JSON.stringify({
            chemicalName: chemicalName
        }) // Convert JSON data to a string and set it as the request body
      };
    
    getChemicalsURL = `${BASE_API_URL}/industrial-wastewater/v1/stations/${stationId}/readings/chemicals`;

    try {
        console.log(options)
        const response = await fetch(getChemicalsURL, options);
        
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const chemicals = await response.json();

        return chemicals;
    } catch (error) {
        console.error("Error fetching chemicals: ", error);
        return [];
    }
};
