import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Station } from "../types/Station";
import { Reading } from "../types/Reading";
import { Chemical } from "../types/Chemical";
import { getWastewaterReadings } from "../services/readingsService";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { getStations } from "../services/stationsService";
import ChemicalChart from "./ChemicalChart";
import { getChemicalGraphData } from "../services/chemicalsService";

const IndustrialWastewaterTable: React.FC = () => {
  const { sector } = useParams();
  const navigate = useNavigate();
  const [stations, setStations] = useState<Station[]>([]);
  const [selectedStation, setSelectedStation] = useState<Station | null>(null);
  const [readings, setReadings] = useState<Reading[]>([]);
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const [chemicalChartLabels, setChemicalChartLabels] = useState<string[]>([]);
  const [chemicalChartValues, setChemicalChartValues] = useState<number[]>([]);
  const [chemicalChartUnitOfMeasure, setChemicalChartUnitOfMeasure] =
    useState("");
  const [selectedChemical, setSelectedChemical] = useState<string | null>(null);
  const [isChemicalSelectionDisabled, setIsChemicalSelectionDisabled] = useState<boolean>(true);

  const onStationChange = (e: { value: Station }) => {
    setSelectedStation(e.value);
  };

  const setChemicalChartData = (data: Chemical[]) => {
    const labels = data.map((reading) => reading.sampleDate);
    const values = data.map((reading) => reading.measuredValue);

    setChemicalChartLabels(labels);
    setChemicalChartValues(values);

    if (data.length > 0) {
      setChemicalChartUnitOfMeasure(data[0].unitOfMeasure);
    }
  };

  // format sector name
  const formatSectorName = (sector: string | undefined): string => {
    if (!sector) return "";
    return sector
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" ");
  };

  const chemicalOptions = [{label: "Phosphorus", value: "PHOSPHORUS,UNFILTERED TOTAL"}, {label: "Lead", value: "LEAD,     UNFILTERED TOTAL"},
    {label: "Mercury", value: "MERCURY,  UNFILTERED TOTAL"}, {label: "Nitrates", value: "NITRATES TOTAL,   FILTER.REAC"}
  ]

  useEffect(() => {
    getStations("industrial-wastewater").then((data) => {
      setStations(data);
    });
  }, []);

  useEffect(() => {
    if (selectedStation) {
      getWastewaterReadings(selectedStation.companyCode).then((data) =>
        setReadings(data)
      );

      setIsChemicalSelectionDisabled(false)
    }
  }, [selectedStation]);

  useEffect(() => {
    if (selectedStation && selectedChemical) {
    
    getChemicalGraphData(selectedStation.companyCode, selectedChemical).then((data) =>
      setChemicalChartData(data)
    );
    }
  }, [selectedChemical])

  return (
    <div className="card">
      <div className="flex items-center justify-between mb-4">
        <h2 className="mr-4">
          Industrial Wastewater - {formatSectorName(sector)}
        </h2>
        <div className="flex items-center">
          <label htmlFor="workstationDropdown" className="mr-2">
            Workstation:
          </label>
          <Dropdown
            id="workstationDropdown"
            value={selectedStation}
            options={stations}
            onChange={onStationChange}
            optionLabel="worksName"
            placeholder="Select a Workstation"
            className="w-full md:w-14rem"
          />
        </div>
      </div>
      <SelectButton data-testid="chemical-select-buttons" onChange={(e) => setSelectedChemical(e.value)} options={chemicalOptions} disabled={isChemicalSelectionDisabled}/>
      <ChemicalChart
        labels={chemicalChartLabels}
        values={chemicalChartValues}
        unitOfMeasure={chemicalChartUnitOfMeasure}
        chemicalName={selectedChemical}
      />
            <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
          paddingRight: "8%",
        }}
      >
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            value={globalFilter}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setGlobalFilter(e.target.value)
            }
            placeholder="Search..."
            style={{ width: "150%", maxWidth: "400px", marginRight: "10px" }}
          />
        </span>
      </div>
      {selectedStation && (
        <DataTable
          value={readings}
          dataKey="controlPointID"
          metaKeySelection={false}
          tableStyle={{ minWidth: "50rem", maxWidth: "95vw", margin: "2.5vw" }}
          globalFilter={globalFilter}
          filterDisplay="menu"
          sortField="sampleDate"
          sortOrder={1}
          paginator
          rows={20} // number of rows per page
          totalRecords={readings.length} // total # of pages for pagination
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        >
          <Column
            field="parameterName"
            header="Parameter Name"
            sortable
          ></Column>
          <Column
            header="Value"
            body={(rowData) =>
              `${rowData.measuredValue} ${rowData.unitOfMeasure}`
            }
            sortable
          ></Column>
          <Column field="sampleDate" header="Sample Date" sortable></Column>
          <Column
            field="frequency"
            header="Frequency"
            body={(rowData) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {rowData.frequency}
              </div>
            )}
            sortable
          ></Column>
          <Column
            field="controlPointName"
            header="Control Point Name"
            sortable
          ></Column>
        </DataTable>
      )}
      <button className="button back-button" onClick={() => navigate(-1)}>
        Back
      </button>
    </div>
  );
};

export default IndustrialWastewaterTable;
