// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    display: grid;
    gap: 20px; 
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}

.button-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px; 
}

button {
    padding: 15px 20px; 
    font-size: 1.2em;
    border: none;
    border-radius: 5px;
    background-color: #1a3779;
    color: white;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/components/DatasetSelector.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,aAAa;IACb,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,0BAA0B;IAC1B,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".card {\n    display: grid;\n    gap: 20px; \n    padding: 20px;\n    border-radius: 8px;\n    text-align: center;\n}\n\n.button-container {\n    display: grid;\n    grid-template-columns: 1fr;\n    gap: 20px; \n}\n\nbutton {\n    padding: 15px 20px; \n    font-size: 1.2em;\n    border: none;\n    border-radius: 5px;\n    background-color: #1a3779;\n    color: white;\n    transition: background-color 0.3s;\n}\n\nbutton:hover {\n    background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
